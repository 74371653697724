.donationSection{
  margin: 10px 50px;
  overflow-x: hidden;
}

.DonationHeading {
  padding: 10px 50px;
}

.DonationHeading h1 {
  padding: 50px 0 10px 0;
  font-size: 38px;
  color: #363434;
  font-family: "Montserrat", sans-serif !important;
  line-height: 37px;
  margin: 0;
  font-weight: 500;
  text-align: center;
}

.donationRow{
  width: 100%;
  display: flex;
}

.donationColumn{
  flex: 1;
  margin: 15px;
}

.donationCardRow{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.donationCardColumn{
  flex: 1;
  margin: 15px;
}

.new-block-custom {
  background: #fff;
  box-shadow: 1px 5px 35px 10px rgba(204, 204, 204, 0.8);
  padding: 25px 5px;
  border-radius: 15px;
  margin-bottom: 25px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.new-block-custom:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.sec-head {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
  padding: 0 0 10px;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  color: #282525;
}

.sec-head h3 {
  display: block;
}

.sec-head span {
  display: block;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}

.sec-head1 span {
  display: block;
  font-size: 30px;
  font-weight: 500;
}

.new-block-custom .btn1 {
  background-color: #B799FF;
  color: #000;
  border: none;
  font-size: 20px;
  font-weight: 700;
  border-radius: 40px;
  padding: 6px 25px;
  text-decoration: none;
}

.btn1:hover {
  background-color: #ACBCFF;
  color: white;
}

.table {
  width: 100%;
  padding: 0px 50px;
  display: table;
}

.table td {
  width: 34%;
  border-right: 1px solid #e6b745;
  border-bottom: none;
  padding: 6px;
}

.footer-content {
  width: fit-content;
}

.footer-logo {
  padding: 0;
}

.footer-logo h5 {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #B799FF;
}

.inforofbank {
  color: #282525;
  text-align: left;
  display: flex;
  line-height: 1.8rem;
  align-items: baseline;
}

.inforofbank .copybutton {
  display: inline-block;
  padding: 1px 2px;
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.inforofbank p {
  margin: 0rem;
  font-weight: 600;
  font-size: 17px;
}

.orange p {
  border-top: 2px solid #767371;
  color: #B799FF;
  text-transform: uppercase;
  width: 100%;
  font-weight: 550;
  font-size: 17px;
  margin: 0.2rem 0.5rem 0.5rem 0rem;
}
.income p {
  margin: 0;
  font-weight: 600;
  font-size: 17px;
}

.butns {
  background-color: black;
  color: white;
  border: none;
  font-size: 15px;
  font-weight: 400;
  padding: 10px;
  width: 100%;
  text-decoration: none;
}

.black-bg-text {
  /* background-image: url(./../images/download.webp);
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: #B799FF;
  padding: 3rem 0rem;
  color: #fff;
  width: 100%;
  font-family: "Montserrat", sans-serif !important;
}

.black-bg-text h3 {
  font-size: 27px;
  color: black;
  font-weight: 700;
  font-family: "Conv_gilroy-extrabold";
  font-family: "Montserrat", sans-serif !important;
  line-height: 37px;
  margin: 0;
  text-align: center;
}

.butns {
  text-decoration: none;
  font-size: 17px;
  color: black;
  font-weight: 700;
  background-color: #e6b745;
  border-radius: 9px;
  padding: 7px;
}
@media screen and (max-width: 600px) {
  .donationSection{
    margin: 0px;
  }

  .donationRow {
    width: 100%;
    flex-direction: column;
    padding: 0px;
  }

  .donationColumn{
    padding-right: 0px;
  }
  
  .donationCardRow{
    flex-direction: column;
  }

  .butns {
    text-decoration: none;
    font-size: 9px;
    color: white;
    font-weight: 700;
    background-color: black;
    border-radius: 3px;
    padding: 8px;
  }

  .DonationHeading {
    padding: 0px 30px;
  }

  .new-block-custom {
    width: 100%;
  }

  .black-bg-text {
    padding: 1rem 2rem;
  }
  .black-bg-text h3 {
    font-size: 1rem;
  }

  .inforofbank {
    font-size: 14px;
  }

  .inforofbank button {
    margin-left: 0px;
  }

  .new-block-custom {
    padding: 30px;
  }

  .sec-head {
    font-size: 17px;
  }

  .sec-head1 span {
    font-size: 15px;
  }

  .btn2 {
    width: 100%;
    padding: 8px;
    font-size: 9px;
  }

  .footer-logo h5 {
    padding: 10px;
  }

  .table {
    width: 100%;
    padding: 0;
    margin: 0;
    display: block;
  }

  .tableData1 {
    width: 41.5%;
  }

  td.yellow-border {
    border-right: none;
    padding: 0.5rem;
  }
}

