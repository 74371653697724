.timingdiv{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    /* transform: translate(-50%, -50%); */
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    /* border-radius: 10px; */
    background-image: url(https://t4.ftcdn.net/jpg/03/02/35/49/360_F_302354986_P3ghfmCu5fGBFA21uy6dwwtGzEEu1d8G.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    margin-top: 15px;
}


.timingrow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
}

.timingcolumn{
    flex: 1;
    width: fit-content;
}

.timingcolumndiv{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: stretch;
}
.timingcolumndiv svg{
    font-size: 2rem;
}

.timingcolumndivtime{
    width: 20%;
    text-align: end;
    padding-right:20px;
}

.timingcolumndivdescription{
    width: 90%;
    text-align: start;
    padding-left:20px;
}

.timingcolumndivdescription p{
    color: #ffffff;
}

.timingdiv p{
    color: #ffffff;
}

@media screen and (max-width: 600px) {
    .timingdiv{
        margin-top: 0;
    }
    .timingrow{
        width: 100%;
        flex-direction: column;
    }
    .timingcolumndivdescription{
        width: 100%;
    }
    .timingcolumn{
        width: 100%;
    }

}
