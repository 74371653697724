.proposedplandiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 20px 0px;
    margin-top: 30px;
}

.proposedplanheading{
    font-weight: 700;
    color: #B799FF;
    padding-bottom: 10px;
    font-family: "Poppins", Sans-serif;
}

.proposedplanimage{
    border-radius: 15px;
    width: 90%;
    box-shadow: 11px 6px 38px 25px rgba(0, 0, 0, 0.1);
}