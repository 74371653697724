table {
  width: 90%;
  border-collapse: collapse;
  margin-bottom: 2rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}
.tablingdata h1 {
  background-color: #acbcff;
  color: white;
  font-size: 2rem;
  font-weight: 700;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #acbcff;
}

@media (max-width: 600px) {
  /* table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  th,
  td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  th {
    text-align: center;
  } */
  .tablingdata h1 {
    width: 90%;
  }
}
