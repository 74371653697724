.whynilachaldhamdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../images/footerbg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    color: #fff;
    z-index: 1;
    padding: 30px 0px;
}

.whynilachaldhamdiv::after{
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #111111;
    opacity: 0.87;
    z-index: -1;
}

.whynilachaldhamrow{
    display: flex;
    width: 80%;
}

.whynilachaldhamrow1{
    display: flex;
    align-items: center;
    justify-content: center;
}
.whynilachalheading{
    text-align: center;
    font-weight: 700;
}

.whynilachaldhambutton{
    background-color: #B799FF;
    padding: 10px 15px;
    border-radius: 15px;
    border: none;
    color: white;
    font-weight: 700;
}
@media screen and (max-width:600px) {
    .whynilachaldhamrow{
        width: 95%;
    }
}