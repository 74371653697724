.homeee{
    width: 100%;
    height:100%;
    overflow-x: hidden;
}

.homeDescription{
    padding:3rem 0rem;
    font-family: 'Montserrat', sans-serif !important;
    margin-top: 0px;
    color: white;
    background-image: url(./../images/nilachal.png);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: ; */
    /* background-color: #E6FFFD; */
}

.homeDescription h3{
    font-size: 29px;
    color: #030303;
    font-family: 'Montserrat', sans-serif !important;
    line-height: 37px;
    margin: 0;
    font-weight: bold;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .homeee{
        display: block;
        margin-bottom: 0px;
        overflow-x: hidden !important; 
    }
    .homeimage{
        width:100%;
        height: 35vh;
    }

    .homeDescription{
        padding: 1rem 2rem;
    }

    .homeDescription h3{
        font-size: 15px;
        line-height: 20px;
    }
  }

  