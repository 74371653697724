ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

.widget-about p {
  font-size: 20px;
  line-height: 30px;
  color: white !important;
  font-weight: 300;
}

h4 {
  font-family: "Poppins", sans-serif;
  margin: 0;
  font-weight: 400;
  padding: 0;
  color: #363940;
}

a {
  color: #5867dd;
}

.footer-big {
  padding: 30px 0px;
}

.footer-big .footer-widget {
  margin-bottom: 40px;
}

.footer--light {
  /* background-image: url(https://assets-global.website-files.com/5a9ee6416e90d20001b20038/64c21faa4f2a2f5ac63df849_-23.png);
  background-repeat: no-repeat;
  background-size: cover; */
  /* background: #E6FFFD; */
    background-image: url(../images/footerbg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    color: #fff;
    z-index: 1;
    padding-top: 30px;
}

.footer--light::before{
  position: absolute;
  content: '';
  background: url(../images/shape.svg) no-repeat;
  width: 100%;
  height: 273px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.footer--light::after{
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #111111;
  opacity: 0.87;
  z-index: -1;
}

.footer-big .footer-menu ul li a,
.footer-big p,
.footer-big ul li {
  color: white;
}

.footerul li{
  color: white;
}
.footer-big .footer-menu ul li{
  margin-bottom: 15px;
}
/* Responsive Css Code created by : Shift Code Design */

.footer-menu {
  padding-left: 40px;
}
.footerheading {
  font-size: 2rem;
  font-weight: 700;
  color: white;
}
.footerIcon {
  margin-left: 0px;
}

.footerIcon ul {
  list-style-type: none;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  margin: 2rem 0rem 2rem 0rem;
}
.footerIcon ul li {
  text-decoration: none;
  margin: 0.5rem;
}
.footerli{
  text-decoration: none;
  margin-bottom: 20px;
}

.footerlia{
  color: white !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
  
}
.footerIcon ul li a img {
  width: 45px;
}

.footer-menu ul li a {
  font-size: 18px;
  line-height: 32px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
}
.footer-menu ul li a {
  color: white !important;
  font-weight: 600;
}
.footer-menu ul li a:hover {
  color: #b799ff;
}

.footer-widget-title {
  line-height: 30px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}

.mini-footer {
  /* background-image: url(./../images/background-1.png);
  background-repeat: no-repeat;
  background-size: cover; */
  background: #b799ff;
  text-align: center;
  padding: 16px 0;
}

.mini-footer p {
  margin: 0;
  line-height: 26px;
  font-size: 15px;
  color: #ffffff;
}

.mini-footer p a {
  color: #aee2ff;
  font-weight: 500;
}

.mini-footer p a:hover {
  color: #34bfa3;
}

/* Responsive Css Code created by : Shift Code Design */

.widget-about img {
  display: block;
  margin-bottom: 30px;
  max-width: 70%;
}

.widget-about p {
  font-weight: 400;
}

.widget-about .contact-details {
  margin: 30px 0 0 0;
}

.widget-about .contact-details li {
  margin-bottom: 10px;
}

.widget-about .contact-details li:last-child {
  margin-bottom: 0;
}

.widget-about .contact-details li i {
  padding-right: 10px;
  color: #333;
}

.widget-about .contact-details li a {
  color: #5867dd;
}

.footeremailli{
display: flex;
background-color: white;
padding: 10px;
border-radius: 15px;
width: 100%;
justify-content: space-between;
}

.footeremailinput{
  border: none;
  /* padding: 5px 5px 5px 10px; */
  /* border-radius: 15px; */
}

.footertelegramicondiv{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b799ff;
  padding: 0px 10px;
  /* margin: 8px 30px 8px 0px; */
  border-radius: 15px;
}

.footercheckboxli{
  display: flex;
}
.footercheckbox{
  padding: 50px;
  background-color: #000000;
  margin-bottom: 25px;
  margin-right: 10px;
}
/* Responsive Css Code created by : Shift Code Design */

@media (max-width: 600px) {
  .footer-menu {
    padding-left: 0;
  }
  .widget-about img {
    max-width: 50%;
  }
  .footerIcon {
    margin-left: 0px;
  }
  .footerupperinnerdiv {
    flex-direction: column;
  }
  .footerIcon ul {
    padding-left: 0;
  }
  .footeremailli{
    padding: 3px;
    width: 85%;
  }
  .footertelegramicondiv{
    margin: 8px;
  }
}
