.videosectiondiv{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}

.videosectioncolumn{
    display: grid;
    place-items: center;
    width: 50%;
}

.videosectioncolumn1{
    padding-right: 30px;
}

.videosectionvideo{
    width: 80%;
}

.videosectionparagraph{
    text-align: justify;
    width: 100%;
}

@media screen and (max-width:600px) {
    .videosectiondiv{
        flex-direction: column;
    }

    .videosectioncolumn{
        width: 90%;
    }

    .videosectioncolumn1{
        padding-right: 0px;
    }

    .videosectionvideo{
        width: 100%;
    }

    .videosectionparagraph{
        margin-top: 30px;
    }
}