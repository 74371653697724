.contrisection {
  padding: 2rem;
  /* background-color: #ffd30170; */
}
.ongoingseva {
  background-color: #ffd30170;
}
.contrisection h1 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin: 1rem;
}

.contrisection h2 {
  text-align: center;
  font-size: 1.5;
  font-weight: 700;
  margin: 1rem;
  margin-bottom: 2rem;
}

.contriboxupper {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  box-shadow: 1px 5px 35px 10px #bc9f0db8;
  border-radius: 2rem;
}
.contriboxlower p {
  color: rgb(156, 156, 156);
  font-size: 20px !important;
  text-align: justify;
}
.contriboxupper img {
  border-radius: 15px 30px;
  width: 18rem;
}

.contriboxlower h3 {
  font-weight: 700;
}

.contriboxlower {
  width: 60%;
  padding: 0rem;
}

/* CSS */
.button-77 {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01, sans-serif;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 64px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
}

@media screen and (max-width: 600px) {
  .button-77 {
    padding: 19px 32px;
  }
}

.button-77:before,
.button-77:after {
  border-radius: 80px;
}

.button-77:before {
  background-color: rgba(249, 58, 19, 0.32);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.button-77:after {
  background-color: initial;
  background-image: linear-gradient(92.83deg, #B799FF 0, #ACBCFF 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.button-77:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
}

.button-77:active:not(:disabled) {
  color: #ccc;
}

.button-77:active:not(:disabled):after {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(92.83deg, #ACBCFF 0, #AEE2FF 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.button-77:disabled {
  cursor: default;
  opacity: 0.24;
}

/* contributebox2 */
.contriboxlower1 {
  width: 100%;
}
.contriboxlower1 h3 {
  font-weight: 700;
}
.contriboxlower1 p {
  color: rgb(156, 156, 156);
  font-size: 20px !important;
  text-align: justify;
}
.contriboxupper1 {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 1px 5px 35px 10px #bc9f0db8;
  border-radius: 2rem;
  width: 100%;
}
.contriboxupper1 img {
  border-radius: 15px 30px;
  width: 18rem;
}
.prasadam{
    display: flex;
}
