.sliderStyles{
    height: 80vh;
    overflow-y: hidden;
}

.slidestyle{
    width:100%;
    height:100%
}
.imgStyles{
    width: 100%;
    height: 100%;
}

.rightArrowStyles{
    position: absolute;
    top: 50%;
    margin-top:50px;
    transform: translate(0, -50%);
    left: 32px;
    font-size: 45px;
    color: #B799FF;
    z-index: 1;
    cursor: pointer;
}

.gurukulboardrightArrowStyles{
    position: absolute;
    bottom: -83%;
    margin-top:50px;
    transform: translate(0, -50%);
    left: 29%;
    font-size: 45px;
    color: black;
    z-index: 1;
    cursor: pointer;
    background-color: #B799FF;
    padding: 0px 12px;
}

.gurukulboardleftArrowStyles{
    position: absolute;
    bottom: -83%;
    margin-top:50px;
    transform: translate(0, -50%);
    right: 29%;
    font-size: 45px;
    color: black;
    z-index: 1;
    cursor: pointer;
    background-color: #B799FF;
    padding: 0px 12px;
}

.leftArrowStyles{
    position: absolute;
    top: 50%;
    margin-top:50px;
    transform: translate(0, -50%);
    right: 32px;
    font-size: 45px;
    color: #B799FF;
    z-index: 1;
    cursor: pointer;
}

.slidesContainerOverflowStyles{
    overflow: hidden;
    height: 100%,
}

@media screen and (max-width: 600px) {
    .slidecontainer{
        height: 50vh !important;

    }
    .imgStyles{
        width: 100%;
        height: 50vh;
    }

    .slidestyle{
        height: 50vh !important;

    }
    .sliderStyles{
        height: 50vh !important;
    }

    .rightArrowStyles{
        margin-top: 0px;
        top: 37%;
    }

    .leftArrowStyles{
        margin-top: 0px;
        top: 37%;
    }
}
