.newheaderdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #B799FF;
}

.newheaderrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    /* margin-top: 10px; */
}

.newheaderrow1column{
    display: flex;
}

.newheaaderrow1column1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    color: white;
    font-weight: 700;
}

.newheaaderrow1column2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    color: white;
    font-weight: 700;
}

.newheaaderrow1column3{
    display: flex;
    width: 95%;
    background-color: #B799FF;
}

.newheaaderrow1column4{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    color: #ffffff;
    font-size: larger;
}

.newheaderheading{
    font-weight: 800;
    color: white;
}

.statusdiv{
    background-image: url(../images/status-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    font-size: 30px;
    padding-left: 40px;
    padding-right: 10px;
    font-weight: 800;
}
.newheaderspan{
    color: white;
    padding:0px 15px;
    font-size: 30px;
    padding-left: 50px;  
}

@media screen and (max-width:600px) {
    .newheaderdiv{
        display: none;
    }
}