.festupper{
  background-image: #ACBCFF;
  background-position: center;
  background-repeat: no-repeat;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.festupper h1 {
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 70px;
   padding: 0rem 7rem 0.5rem 7rem;
}

.festivalpara {
  font-size: 20px !important;
  text-align: justify;
  color: #696363;
}

.festivalimage {
  width: 50rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* text-align: center; */
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 2rem 2rem 7rem 2rem;
  margin-bottom: 1rem;
}

.festivaldiv{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.festsection{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contributediv{
  width:85%;
}

.festivalrow{
  display: flex;
  width: 85%;
}

.festivalcolumn0{
  width: 70%;
}

.festivalcolumn1{
  width: 30%;
  padding-left: 40px;
}

.historyofiskondivpara{
  padding: 30px;
}

.srilaprabhuimage{
  width: 350px;
  height: 350px;
}

.historyofiskondiv{
  margin-bottom: 30px;
}

.contributediv{
  text-align: center;
  background-image: url(./../images/contributiondivimage.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 40vh;
  border-radius: 0px 20px 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contributedivheading{
font-weight: 800;
font-size: 40px;
color: white;
}

.contributedivpara{
  color: white;
  padding: 0px 30px;
  text-align: center;

}

.donatenowbutton{
  background-color: #fd854a;
  border: none;
  padding: 15px;
  color: white;
  font-weight: 700;
  border-radius: 0px 20px 20px 20px;
}

@media screen and (max-width: 600px) {
    .festsection img{
        width: 21rem;
    }
    .festivalpara{
        padding: 0rem 1rem 0.5rem 1rem;
        font-size: 20px !important;
        text-align: justify;
        color: #696363;
    }
    .festupper {
        padding: 6rem 0rem 6rem 0rem;
    }
    .festupper h1 {
        text-align: center;
        color: white;
        font-weight: 700;
        font-size: 30px;
    }
    .festivalrow{
      flex-direction: column;
    }

    .festivalcolumn0{
      width: 100%;
    }

    .festivalcolumn1{
      width: 100%;
      padding-left: 0px;
      padding: 0px 20px;
    }

    .historyofiskondiv1{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
