.maindivsticky {
    border-radius: 0rem 1rem 0rem 0rem;
  padding: 2rem;
  width: fit-content;
  text-transform: capitalize;
  /* display: flex; */
  /* align-items: flex-end; */
  /* flex-direction: column; */
  /* justify-content: flex-start; */
  
  background-color: #acbcff;
}
.maindivsticky a {
  text-decoration: none;
  color: white;
  line-height: normal;
  font-size: 1rem;
  font-weight: 700;
}
.maindivstickytop {
  position: fixed;
  left: 0;
  top: 20rem;
  z-index: 20;
  width: fit-content;
  
}
.maindivsticky1{
    border-radius: 0rem 0rem 1rem 0rem;
    width: fit-content;
    padding: 0.8rem;
}