.information {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 2rem 5rem 2rem 5rem;
}
.whatsapp {
  width: fit-content;
  width: 65%;
}
.whatsapp p {
  width: 80%;
  font-size: x-large;
  font-weight: 500;
  font-style: italic;
  /* padding-left: 3rem; */
}
.whatsapp p span {
  color: #B799FF;
  font-weight: bold;
}

.whatsapp button {
  color: #fff;
  background-color: #B799FF;
  /* border-color: #fd854a; */
  border: none;
  padding: 10px 0px;
  font-size: 22px;
  font-weight: 500;
  /* margin-left: 3rem; */
  padding: 0.5rem;
  border-radius: 5px;
}
.banking {
  width: 85%;
  margin: 0rem 2rem 0rem 2rem;
}

.banking .blockofcon {
  padding: 7px 19px 7px 53px;
  /* background-image: url(./../images/download.webp);
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: #B799FF;
  color: white;
  font-size: 20px;
  font-weight: 700;
  border-radius: 3px;
  width: 100%;
  max-width: 500px;
  /* left: 9%; */
  position: relative;
  margin-bottom: 1rem;
}
.qr img {
  width: 15rem;
}

.vl {
  border-left: 2px solid black;
  height: 380px;
  margin-left: -3px;
  margin: 0rem 1rem 0rem 1rem;
}

.inforofbank {
  color: #282525;
  text-align: left;
  display: flex;
  align-items: baseline;
}
.inforofbank button {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.inforofbank p {
  margin: 0rem;
  font-weight: 700;
  font-size: larger;
}

.banking .orange p {
  color: #B799FF;
  text-transform: uppercase;
  width: 100%;
  font-weight: 700;
  font-size: larger;
  margin: 1rem 1rem 1rem 0rem;
}
.banking .income p {
  margin: 0;
  font-weight: 700;
  font-size: larger;
}
.qr .qrh2 {
  font-size: larger;
  font-weight: 600;
}
.qr p {
  font-size: 16px;
  text-align: left;
  font-weight: 600;
}

.csr a {
  color: #B799FF;
  text-decoration: none;
}

.csr {
  text-align: right;
}
.roww {
  padding-right: 5rem;
  font-size: x-large;
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
  .information {
    flex-direction: column;
    margin: 2rem 0rem 2rem 0rem;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .vl {
    display: none;
  }
  .whatsapp p {
    width: 100%;
    text-align: justify;
    padding: 1rem;
  }
  .whatsapp {
    width: fit-content;
    width: 100%;
    padding-bottom: 2rem;
  }
  .banking .blockofcon {
    left: 0;
    padding: 7px 7px 7px 7px;
    background-color: #1a1512;
    color: #fff;
    font-size: 17px;
  }
  .whatsapp button {
    margin-top: -1rem;
    margin-left: 1rem;
  }
  .inforofbank {
    color: #282525;
    text-align: left;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .qr{
    padding-left: 1.5rem;
    padding-top: 2rem;
  }
  .qr .qrh2 {
    font-size: 1.7rem;
    font-weight: 700;
}
.qr img {
  width: 17rem;
}
.qr p {
  margin-top: 1rem;
  font-size: 20px;
  text-align: left;
  font-weight: 700;
}
.csr {
  text-align: center;
}
.roww {
  padding-right: 0rem;
}
}
