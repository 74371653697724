.aboutussection h1 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  color: #acbcff;
  margin: 2rem;
}
.aboutusnila{
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutusnilavideodiv{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.aboutusnilavideodiv1{
  padding-right: 30px;
}
.aboutusnila img {
  width: 40%;
  border-radius: 2rem;
  box-shadow: 10px 10px 5px #aee2ff;
}
.aboutusnila p {
  width: 100%;
  /* margin: 0px 20px; */
  text-align: justify;
}
.aboutusnila {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.aboutusinner {
  margin-bottom: 3rem;
}
.aboutusinner h1,
.aboutusmissioninner h1,
.aboutusmitho h1 {
  color: black;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin: 2rem;
}
.aboutusinner h1 span,
.aboutusmissioninner h1 span,
.aboutusmitho h1 span {
  color: #acbcff;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
}
.aboutusinner p {
  margin-left: 2rem;
}
.aboutusinner {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.aboutusinner ul li {
  list-style: disc;
  color: #898b96;
}
.abotusmission {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.aboutusmissioninner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  border-radius: 2rem;
  box-shadow: 11px 6px 38px 25px rgba(0, 0, 0, 0.1);
}
.aboutusmissioninner p {
  text-align: justify;
  padding: 2rem;
}
.aboutusmitho p {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 600px) {
  .aboutusnila {
    flex-direction: column;
  }
  .aboutusnilavideodiv{
    width: 100%;
  }
  .aboutusnilavideodiv1{
    padding-right: 0px;
  }
  .aboutusnila p {
    width: 90%;
  }
  .abotusmission{
    flex-direction: column;
  }
  .aboutusnila img{
    width: 90%;
    margin-bottom: 2rem;
  }
  .aboutusmissioninner{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
}
