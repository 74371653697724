 .socialMediaBack {
  background-image: url(https://assets-global.website-files.com/5a9ee6416e90d20001b20038/64c14f36767c0e634bd3909a__1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: #8a8989; */
   /* background-size: 100% 100%; */
  padding: 3rem 1rem 3rem 1rem;
}
.headingOfSocialMedia {
  
  color: #B799FF;
  font-size: 38px;
  font-weight: 700;
  padding: 20px 0 10px 0;
  text-align: center;
}
.imagesSocial {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
/*
.images {
  margin: 1rem;
  width: 25%;
  border-radius: 5px;
}


.Imagess{
  --s: 25px;  /* size of the frame */
/* --b: 4px;   /* border thickness */
/* --w: 250px; /* width of the image */
/* --c: #d6772a; */
/*   
  width: var(--w);
  aspect-ratio: 1;
  object-fit: cover;
  padding: calc(2*var(--s));
  --_g: var(--c) var(--b),#0000 0 calc(100% - var(--b)),var(--c) 0;
  background:
    linear-gradient(      var(--_g)) 50%/100% var(--_i,100%) no-repeat,
    linear-gradient(90deg,var(--_g)) 50%/var(--_i,100%) 100% no-repeat;
  outline: calc(var(--w)/2) solid #2d2c2c30;
  outline-offset: calc(var(--w)/-2 - 2*var(--s));
  transition: 0.6s;
  cursor: pointer;
  margin: 1rem;
} */
/* img:hover {
  outline: var(--b) solid var(--c);
  outline-offset: calc(var(--s)/-2);
  --_i: calc(100% - 2*var(--s));
} */

/* body {
  /* margin: 0;
  min-height: 100vh;
  display: grid;
  place-content: center;
  align-items: center;
  grid-auto-flow: column;
  gap: 20px;
  background: #e8e8e8; */
/* } */

@media only screen and (max-width: 600px) {
  .imagesSocial {
    display: flex;
    flex-direction: column;
  }
  .headingOfSocialMedia {
    font-size: 37px;
  }
  /* .images {
    margin: 1.7rem 0px 1.7rem 0px;
    width: 90%;
    border-radius: 5px;
} */
} 

/* #app {
  height: 100%;
} */
/* html, */
/* body {
  position: relative;
  height: 100%;
} */

/* body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
} */

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height:40vh
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}
.swiper-3d .swiper-slide-shadow, .swiper-3d .swiper-slide-shadow-left, .swiper-3d .swiper-slide-shadow-right, .swiper-3d .swiper-slide-shadow-top, .swiper-3d .swiper-slide-shadow-bottom, .swiper-3d .swiper-slide-shadow, .swiper-3d .swiper-slide-shadow-left, .swiper-3d .swiper-slide-shadow-right, .swiper-3d .swiper-slide-shadow-top, .swiper-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 0%;
  pointer-events: none;
  z-index: 10;
}
