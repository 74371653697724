.gurukulhelp {
  display: flex;
  margin: 3rem 0rem 3rem 0rem;
  justify-content: space-around;
}
.gurukulhelpinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 2rem;
  box-shadow: 0px 0px 41px 22px #c3b4e940;
  padding: 1rem;
  margin: 2rem;
}
.gurukulhelpinner a,
.gurukulhelpinner p {
  text-decoration: none;
  color: grey;
  font-weight: 700;
  font-size: 1.2rem;
}
.innergurukul {
  background-color: #b799ff;
  border-radius: 100%;
  padding: 1rem;
}
.innergurukul svg {
  width: 100%;
  font-size: 2.5rem;
  color: white;
}

@media only screen and (max-width: 600px) {
  .gurukulhelp {
    flex-direction: column;
    
  align-items: center;
  }
  .gurukulhelpinner {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin: 1rem 0rem 1rem 0rem;
  }
}
