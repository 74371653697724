.activitydiv{
    background-image: url(https://assets-global.website-files.com/5a9ee6416e90d20001b20038/64c14f36767c0e634bd3909a__1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: #ACBCFF; */
    display: flex;;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 6rem;
    padding-top: 2rem;
}
.activityheading{
    padding-top: 30px;
    font-weight: 600;
}

.activityrow{
    display: flex;
    width: 90%;
}
.activitycolumn{
    flex: 1;
    border-radius: 15px 15px 15px 15px;
    box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.3);
    margin: 10px;
    cursor: pointer;
    background-color: #fff;
    width: 30%;
    background-image: url(./../images/nilachal.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.activitycolumnimage{
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 30px;
}

.activityimage{
    width: 100%;
    height: 200px;
    border-radius: 30px;
    box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.3);
}

.activitycolumndescription{
    width: 100%;
    height: 40vh;
    text-align: left;
    padding: 30px 10px;
}

.activitycolumnlink{
    padding: 10px;
}
.activitylink{
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .activityrow{
        flex-direction: column;
    }
    .activitycolumn{
      width: 95%;
      text-align: center;
      flex-direction: column;
    }

    .activitycolumnimage{
        width: 100%;
        padding: 10px;
    }

    .activitycolumndescription{
        width: 100%;
        text-align: center;
        padding: 30px 15px;
    }
  
   
  }