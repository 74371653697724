.navbar {
  display: flex !important;
  /* justify-content: space-between; */
  color: #0a0a0a;
  padding: 5px;
  /* height: 42px; */
  /* background-image: url(./../images/download.webp);
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: #fff;
  align-items: baseline !important;
  height: 6vh;
  --bs-navbar-padding-y:0rem !important;
  /* margin-top: -0.6rem; */
  margin-bottom: 0.5rem;
  align-items: center !important;
}
.marqueing{
  width: 62%;
}
.foot{
  font-size: 20px;
  color: #ffffff;
  background-color: #b60d0d;
  padding:5px 0px 5px 5px;
  /* clip-path: polygon(0 6%, 0% 100%, 100% 100%); */
}



.marqueing marquee{
  font-size: 17px;
  font-weight: 700;
  color: maroon;
  /* padding: 10px; */
}

.marquee-content{
  
}

.logo {
  width: 85px;
  height: auto;
}

.btn {
  padding-bottom: 16px;
  color: rgb(222, 198, 62);
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.liveDarshan {
  display: flex;
  /* align-items: flex-start; */
  align-items: baseline;
}
.liveDarshan li {
  list-style: none;
  padding: 2px 8px 2px 8px;
}
.liveDarshan li a {
  text-decoration: none;
  font-size: 18px;
  color: #101010;
}

.liveDarshan li img {
  width: 70px;
  padding: 0px;
}
.blink_me {
  animation: blinker 3s linear infinite;
  color: rgb(255, 1, 1);
  font-size: 15px;
}

.blink_me_youtube {
  animation: blinker 6s linear infinite;
  color: rgb(255, 1, 1);
  font-size: 15px;
}

@keyframes blinkercall {
  0%, 100% {
    transform: rotate(-20deg) translateX(-2px);
  }
  50% {
    transform: rotate(20deg) translateX(2px);
  }
}

.element-to-animate {
  animation: blinkercall 3s linear infinite;
}


@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* @media screen and (max-width: 767px) and (max-width: 1024px) {
  


} */

@media screen and (max-width: 600px) {
  .navbar {
    display: none;
  }
  .foot{
    display: none;
  }
  .marqueing{
    width: 45%;
    display: none;
  }
  .navbar {
    /* display: flex; */
    /* justify-content: flex-start; */
    color: #fff;
    padding: 5px;
    height: 85px;
    /* background-color: #ffd301; */
    /* flex-direction: row !important; */
    flex-wrap: nowrap;
    align-items: flex-end;
    margin-top: -37px;
    flex-direction: column;
    align-items: center;
  }
  .liveDarshan {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 0px;
    /* align-items: flex-end; */
    padding-top: 15px;
    margin: 0;
  }
  .liveDarshan li a {
    font-size: 13px;
  }
  .logo {
    height: 80px;
  }
  .blink_me {
    font-size: 13px;
  }
  .displayres {
    display: none !important;
  }
  .navbar {
    height: 39px !important;
    margin-top: 0px !important;
  }
  .rowww img {
    width: 14rem;
}
}
