.homeinfoslider{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
    background-image: url(https://t4.ftcdn.net/jpg/04/34/70/73/360_F_434707355_AOOnuztvZhSBNwVgg0y113rEYAvl9q49.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: #B799FF; */
    margin: 2rem 0rem 2rem 0rem;
}

.carousel {
    height: 50vh;
    perspective: 250px;
}
.carousel-item{
    padding: 2rem;
}

.carousel-item p{
    color: white;
}

@media (max-width: 600px) {
    .carousel-item{
        padding: 0.5rem;
    }
    .carousel {
        width: 370px;
    }
    .homeinfoslider{
        flex-direction: column;
    }
}