.donationchild {
  background-image: url(./images/img8.webp);
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 2rem;
  padding: 10rem;
}
.donationchild h1 {
  opacity: 0.7;
  color: white;
  font-weight: 600;
}
.donationchild button {
  border-radius: 2rem;
  color: white;
  padding: 0.7rem 2rem 0.7rem 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: #b799ff;
  border: none;
}
.iskcongurukulainner{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.iskcongurukula{
    margin-bottom: 2rem;
}
.iskcongurukula h1{
    color: #b799ff;
    font-size: xxx-large;
    font-weight: 600;
    margin-left: 3rem;
}
.iskcongurukula h1 span {
    color: #b799ff;
    font-size: xx-large;
    font-weight: 600;
    font-family: cursive;
}
.iskcongurukulainner img{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}
.iskconguru{
  width: 55%;
}
.iskconguru p{
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
@media only screen and (max-width: 600px) {
  .donationchild {
    padding: 1rem;
  }
  .donationchild button {
    border-radius: 2rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    background-color: #b799ff;
    border: none;
  }
  .iskcongurukulainner {
    flex-direction: column;
  }
  .iskcongurukulainner img{
    width: 90%;
  }

}
