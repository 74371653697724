.whatsinsidediv{
    background-image: url(https://assets-global.website-files.com/5a9ee6416e90d20001b20038/64c21faa4f2a2f5ac63df849_-23.png);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: #E6FFFD; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 50px;
    max-height: 100%;
}

.whatsinsideheading{
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;
}

.whatsinsiderow{
    display: flex;
    justify-content: space-between;
    width: 78%;
}

.whatsinsidecolumn{
    flex: 1;
    width: 25%;
    border-radius: 50px 50px 0px 0px;
    margin: 25px;
    padding: 5px;
    text-align: center;
    height: 280px;
    box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.3);
    /* border-radius: 10px; */
    cursor: pointer;
    transition: transform 0.3s ease;
    background-color: #fff;
}

.whatsinsidecolumn:hover{
    transform: scale(1.1);
  }

.whatsinsidecard{
    border-radius: 10px;
}
.whatsinsideimage{
    height: 200px;
    width: 100%;
    vertical-align: top;
    border-radius: 50px 50px 0px 0px;
}

.whatsinsidepara{
    font-size: 30px;
    font-weight: 600;
    margin: 20px 0px;
}

@media screen and (max-width: 600px) {
    .whatsinsiderow{
      flex-direction: column;
    }
  
    .whatsinsidecolumn{
      width: 100%;
      margin: 15px 15px 15px 0px;
    }
  }