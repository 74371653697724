.uppergurukulaboard h1 {
  color: #b799ff;
  text-align: center;
  margin: 2rem;
  font-weight: 700;
}

.innercaraosalboard{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gurukulboardrow{
  width: 40%;
  height: 50vh;
}
.innercaraosalboard h1 {
  color: black;
  text-align: center;
  margin: 2rem;
  font-weight: 700;
}
.innercaraosalboard h1 span {
  color: #b799ff;
  text-align: center;
  font-weight: 700;
}

.uppergurukulaboardrow{
  display: flex;
  margin-bottom: 30px;
}

.uppergurukulaboardimgdiv{
  width: 45%;
}

.uppergurukulaboardparadiv{
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 30px; 
}

.uppergurukulaboardimg{
  width: 100%;
  height: 350px;
}

.uppergurukulaboard p {
  width: 95%;
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
}
.gurukulamedium {
  width: 95%;
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
}
.gurukulamedium ul li {
  color: #898b96;
  list-style: disc;
}
.gurukulamedium p a {
  width: 95%;
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  color: #b799ff;
}
.innercaraosal {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.styles-module_carousel-arrow__26sRw{
  position: absolute;
  top: 30%;
  background-color: #b799ff;
  padding: 15px;
  border-radius: 50%;
  border: none;
}

.styles-module_carousel-arrow__26sRw::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 0 8px 14px;
  border-color: transparent transparent transparent black;
}

@media screen and (max-width:600px) {
  .uppergurukulaboardrow{
    flex-direction: column-reverse;
  }
  .uppergurukulaboardimgdiv{
    width: 100%;
  }
  .uppergurukulaboardparadiv{
    width: 100%;
    padding: 10px;
  }
}