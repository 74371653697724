.contributiondiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upcomingfestivaldiv{
    display: flex;
    flex-direction: column;
    width: 90%;
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    margin: 30px 0px;
    border-radius: 10px;
}

.upcomingfestivalrow{
    display: flex;
    align-items: center;
    justify-content: center;
}
.upcomingfestivalheading{
    font-weight: 600;
    font-size: 40px;
}

.upcomingfestivalpara{
    text-align: center;
    font-size: 20px;
    width: 85%;
}

.upcomingfestivalcolumn{
    border-radius: 40px 10px 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    flex: 1;
    margin-bottom:30px ;
    height: 70vh;
    margin: 30px;
}

.upcomingfestivalinnercolumn1{
    width: 100%;
    border-radius: 40px 0px 0px 0px;
}

.upcomingfestivalinnercolumn2{
    width: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding: 18px;
    /* background-color: white; */
}

.upcomingfestivalimage{
    width: 100%;
    height: 250px;
    border-radius: 40px 10px 80px 10px;
}

.upcomingfestivalinnerheading{
    font-weight: 700;
    font-size: 30px;
    text-align: start;
}

.upcomingfestivalinnerheading1{
    font-weight: 700;
    font-size: 20px;
    text-align: start;
}

.upcomingfestivalinnerbutton{
    padding: 10px 20px;
    background-color: #ffd301;
    border: 2px solid #ffd301;
    border-radius:15px ;
}

.upcomingfestivalinnerbutton:hover{
    background-color: #ff9101;
    color: #ffffff;
    border: 2px solid #ff9101;
}

.prasadamsevadiv{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
background-repeat: no-repeat;
background-size: cover;
width: 85%;
border-radius: 10px;
padding-top: 30px;
margin-bottom: 30px;
}

.prasadamsevarow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.upcomingfestivalinnercolumn2inner2{
    display: flex;
    align-items: flex-start;
}

.prasadamsevacolumn{
    margin: 20px;
    background-color: white;
    border-radius: 10px;
}

.prasadamsevainnercolumn2{
    padding: 10px 0px 20px 15px;
}

.prasadamsevaimage{
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0px 0px;
}

.donationdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.donationrow{
    display: flex;
    flex-direction: row-reverse;
    background-image: url(../images/nilachal.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 85%;
    border-radius: 15px;
    box-shadow: 5px 5px 5px 5px #eee;
    margin-bottom: 30px;
}

.donationcolumn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 30px;
}

.donationimage{
    width: 100%;
    height: 300px;
    border-radius: 15px;
}

.donationparagraph{
 text-align: justify;
 padding-left: 30px;
}

@media screen and (max-width:600px) {
    .upcomingfestivaldiv{
        width: 95%;
    }
    .upcomingfestivalrow{
        flex-direction: column;
    }
    .upcomingfestivalpara{
        padding: 0px 20px;
    }
    

    .upcomingfestivalinnercolumn1{
        width: 100%;
    }

    .upcomingfestivalinnercolumn2{
        width: 100%;
    }

    .prasadamsevarow{
        flex-direction: column;
    }
    .donationrow{
        flex-direction: column;
        width: 95%;
    }
    .donationrow1{
        flex-direction: column-reverse;
    }
    .donationcolumn{
        width: 100%;
        padding: 10px;
    }
    .donationparagraph{
        padding-top: 30px;
        padding-left: 0px;
    }
}
