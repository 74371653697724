.gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .row2 {
    display: flex;
    width: 100%;
  }
  
  .pics {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 30px;
    width: 21%;
    padding: 15px;
    background:#eeeeee;
  }

  .pics:hover{
    background-color: #B799FF;
  }
  
  .galleryimage {
    width: 100%;
    height: 250px;
    object-fit: cover;
    cursor: pointer; 
  }
 
 .galleryimage:hover{
     filter: opacity(.6);
 }
 
 .model{
     width: 100%;
     height: 100vh;
     position: fixed;
     top: 0;
     left: 0;
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: #000000;
     transition:opacity .4s ease,visibility .4s ease, transform .5s ease-in-out;
     visibility: hidden;
     opacity: 0;
     transform: scale(0);
     overflow: hidden;
     z-index: 999;
 }
 
 .model.open{
     visibility: visible;
     opacity: 1;
     transform: scale(1);
 }
 
 .modelimage{
     width: auto;
     max-width: 100%;
     height: auto;
     max-height: 100%;
     display: block;
     line-height: 0;
     box-sizing: border-box;
     padding: 0 20px 20px;
     margin: 0 auto;
 
 }
 
 .modelcloseButton{
     position: fixed;
     top: 10px;
     right: 10px;
     width: 2rem;
     height: 2rem;
     padding: 5px;
     background-color: rgba(0, 0, 0, 0.4);
     color: #ffffff;
     cursor: pointer;
 }

 @media screen and (max-width:600px) {
    .row2{
        flex-direction: column;
    }

     .pics{
        margin: 10px;
        width: 95%;
     }
 }