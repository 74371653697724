.eventdiv{
    display: flex;;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0rem;
}
.eventheading{
    padding-top: 10px;
    font-weight: 600;
}

.eventrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.eventcolumn{
    background-image: url(https://assets-global.website-files.com/5a9ee6416e90d20001b20038/64c21fa2d9517897c63018d8_-13.png);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: #ACBCFF; */
    /* width: 60%; */
    flex: 1;
    border-radius: 15px;
    box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.3);
    margin: 10px;
    cursor: pointer;
    height: 65vh;
}

.eventcolumnimage{
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 10px;
}

.eventimage{
    width: 100%;
    height: 250px;
    border-radius: 30px;
}

.calenderimg{
    height: 30px;
    margin-right: 10px;
}

.eventcolumndescription{
    text-align: center;
    width: 100%;
    text-align: left;
    padding: 20px 10px;
    height: 25vh;
}

.calenderheading{
    padding-left: 10px;
    color: darkmagenta;
}
@media screen and (max-width: 600px) {
    .eventcolumn{
      width: 85%;
      text-align: center;
      flex-direction: column;
    }

    .eventcolumnimage{
        width: 100%;
        padding: 20px;
    }

    .eventrow{
        width: 95%;
        flex-direction: column;
    }

    .eventcolumndescription{
        width: 100%;
        text-align: center;
        padding: 15px;
    }
    .calenderheading{
        padding-left: 0;
        margin: 20px 0px;
    }
  
   
  }