.faqsectionreal{
    width: 90%;
    margin: auto;
    margin-bottom: 2rem;
}
.styles_faq-row-wrapper__3vA1D h2{
    text-align: center;
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB {
    color: var(--row-content-color, rgb(0, 0, 0));
    font-size: var(--row-content-text-size, medium);
    padding: var(--row-content-padding-top, 0) var(--row-content-padding-right, 0) var(--row-content-padding-bottom, 0) var(--row-content-padding-left, 0);
    text-align: justify;
}