.admissionhelpdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0px;
}
.script{
    font-family: "Seaweed Script";
    color: black;
    text-align: center;
    font-size: 40px;
    position: relative;
    line-height: 100%;
    margin: 0;
    width: 80%;
}

.script:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 50%;
    width: 100%;
    opacity: 0.8;
    border-bottom: 3px solid #B799FF;
}

.script span {
    background-color: white;
    padding: 0 0.3em;
}

.text-color {
    color: #B799FF !important;
}

.admissionhelprow{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
    margin-top: 30px;
}

.admissionhelpcolumn1{
    display: flex;
    width: 100%;
}

.admissionhelpcolumn2{
    display: flex;
    width: 100%;
}

.admissionhelpcolumn2column1{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admissionhelpcolumn2column2{
    width: 60%;
    padding: 30px 30px 0px 30px;
}

.admissionhelpcolumninnerdiv{
    width: 33%;
    margin: 30px;
}

.admissionhelpicondiv{
    background-color: #B799FF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
    padding: 10px 0px;
    font-weight: 700;
}


.admissionhelpinfodiv{
    text-align: center;
    box-shadow: 2px 2px 2px 2px #eee;
    padding: 10px 0px;
}

.admissionhelpli{
    padding: 0px 50px;
}

@media screen and (max-width:600px) {
    .admissionhelprow{
        width: 95%;
        flex-direction: column;
    }
    .admissionhelpcolumn1{
        flex-direction: column;
        width: 100%;
    }

    .admissionhelpcolumn2{
        flex-direction: column;
        width: 100%;
    }

    .admissionhelpli{
        padding: 0px 20px;
    }
    .admissionhelpcolumninnerdiv{
        width: 100%;
        margin: 0px 0px 30px 0px;
    }
    .admissionhelpcolumn2column1{
        width: 100%;
    }
    .admissionhelpcolumn2column2{
        width: 100%;
        padding: 0;
    }
}