.gurukulteachermanagement h1 {
  color: black;
  text-align: center;
  margin: 2rem;
  font-weight: 700;
}
.gurukulteachermanagement h1 span {
  color: #b799ff;
  text-align: center;
  font-weight: 700;
}
.gurukulteachermanagementinner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.gurukulteachername img {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 1.2rem;
  border-radius: 2rem;
}
.gurukulteachername h2 {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
.gurukulteachername {
  width: fit-content;
  border-radius: 2rem;
  box-shadow: 0px 0px 41px 22px rgba(0, 0, 0, 0.1);
  margin: 2rem;
  width: 25%;
}
.gurukulteachername {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards; /* Adjust the duration and easing as needed */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.gurukulteachername img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.gurukulteachername:hover img {
  transform: scale(1.1); /* Adjust the scaling factor as needed */
}
@media only screen and (max-width: 600px) {
  .gurukulteachername {
    width: 100%;
  }
}
