.mobileviewheaderdiv{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #B799FF;
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 999;
}

.mobileviewheaderrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  
}

.newheaaderrow1column1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    color: white;
    font-weight: 700;
    padding: 10px 0px;
}


.callusdiv{
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 60%;
    border-radius: 10px;
}

.callusdivpara{
    margin-bottom: 5px;
    color: black;
    font-weight: 600;
}

.emaildivpara{
    margin-bottom: 5px;
    color: black;
    font-weight: 600;
}

.emaildiv{
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 60%;
    border-radius: 10px;
}

.modelcloseButton1{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    color: black;
    font-size: 20px;
    cursor: pointer;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white;
}

.mobilecopynumber{
    background-color: #eee;
    border: none;
    border-radius: 10px;
    padding: 5px 15px;
}
.emailcopybutton{
    background-color: #eee;
    border: none;
    border-radius: 10px;
    padding: 5px 15px;
}

@media screen and (max-width:600px) {
    .mobileviewheaderdiv{
        display: flex;
        position: sticky;
    }
    .callusdiv{
        display: block;
    }
    .emaildiv{
        display: block;
    }
}